<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="isCreated ? '新增' : '编辑'"
    :visible.sync="show"
    width="800px"
    class="add-account"
    v-loading="loading"
  >
    <el-form
      size="small"
      :model="params"
      ref="form"
      label-width="120px"
      :rules="rules"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="配件类型名称" prop="name">
            <el-input v-model="params.name" placeholder="请输入配件类型名称" />
          </el-form-item>
        </el-col>
      </el-row>
	  <el-row :gutter="10">
	    <el-col :span="12">
	      <el-form-item label="备注" prop="remark">
	        <el-input v-model="params.remark" placeholder="请输入备注" />
	      </el-form-item>
	    </el-col>
	  </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确定</el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    const rules = {
      name: [{ required: true, message: '请选择配件类型名称' }]
    }

    return {
      loading: false,
      rules,
      params: {
        name: '',
        remark: ''
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.params = { ...this.currentItem }
    }
  },

  methods: {
    async submitForm() {
      console.log(this.params)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            let params = { ...this.params }
            if (this.isCreated) {
              await this.$api.workOrder.insertPartsType(params)
            } else {
              await this.$api.workOrder.updatePartsType(params)
            }

            this.$message.success('操作成功')
            this.$emit('updateList')
            this.show = false
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
