<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="配件类型名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
      </el-form>
    </template>
    <div style="margin-bottom: 12px">
      <el-button type="primary" v-auth="'insert'" @click="toInsert()">
        新 增
      </el-button>
    </div>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="name"
          label="配件类型名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="remark"
          label="备注"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="updateTime"
          label="更新时间"
        />
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="200"
        >
          <template slot-scope="{ row }">
            <el-button size="medium" type="text" @click="toUpdate(row)">
              编辑
            </el-button>
            <el-button size="medium" type="text" @click="toDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNum"
      @change="getData"
    />

    <AddModal
      :currentItem="currentItem"
      v-if="showUpdateModal"
      :visible.sync="showUpdateModal"
      @updateList="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
export default {
  components: { AddModal },
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      showUpdateModal: false,
      params: {
        page: 1,
        limit: 20,
        name: ''
      },
      total: 0
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        const { records, total } = await this.$api.workOrder.listPartsType(
          params
        )
        this.tableData = records || []
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toInsert() {
      this.currentItem = null
      this.showUpdateModal = true
    },
    async toUpdate(row) {
      this.currentItem = row
      this.showUpdateModal = true
    },
    async toDelete(row) {
      try {
        await this.$confirm('确定删除数据吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.workOrder.deletePartsType(row.id)
        this.getData()
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    formatDate(date) {
      return this.$format.dateFormat(date, 'YYYY-mm-dd')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .page-content {
  padding-top: 0px !important;
}
</style>
